import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import BttImg from '@/assets/img/for-every-child/endviolence_bn_img@2x.png';
import BttImgM from '@/assets/img/for-every-child/endviolence_bn_imgM@2x.png';
import Obj1 from '@/assets/img/for-every-child/endviolence_obj1@2x.png';
import Obj2 from '@/assets/img/for-every-child/endviolence_obj2@2x.png';
import Obj3 from '@/assets/img/for-every-child/endviolence_obj3@2x.png';
import Obj4 from '@/assets/img/for-every-child/endviolence_obj4@2x.png';
import Obj5 from '@/assets/img/for-every-child/endviolence_obj5@2x.png';
import WedoImg from '@/assets/img/for-every-child/endviolence_wedo_img@2x.png';
import WedoImgM from '@/assets/img/for-every-child/endviolence_wedo_imgM@2x.png';
import Button from '@/components/Button';
import CampaignCard2, { CampaignData } from '@/components/Card/CampaignCard2';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import Image from '@/components/Image';
import { SectionTop } from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const itemData = [
  {
    id: 1,
    img: Obj1,
    desc: [
      {
        dec: `매년 전 세계 어린이 2명 중 1명, <strong>10억 명</strong>이 폭력으로 고통받습니다.`,
      },
    ],
  },
  {
    id: 2,
    img: Obj2,
    desc: [
      {
        dec: `2~4세 어린이 약 <strong>3억 명</strong> 이상이 양육자 및 보호자로부터 신체적 학대를 받습니다.`,
      },
    ],
  },
  {
    id: 3,
    img: Obj3,
    desc: [
      {
        dec: `11~15세 학생 중 
        <strong>약 32%</strong>가 학교에서 
        괴롭힘을 당합니다.`,
      },
    ],
  },
  {
    id: 4,
    img: Obj4,
    desc: [
      {
        dec: `13~15세 학생 중 약 <strong>36%</strong>가 다른 친구들을 괴롭힌 경험이 있습니다.`,
      },
    ],
  },
  {
    id: 5,
    img: Obj5,
    desc: [
      {
        dec: `20세 이하 여자 어린이 <strong>1억 2천만 명</strong>이 성추행, 성폭력을 경험한 적이 있습니다.`,
      },
    ],
  },
];
/*
const CampaignData = [
  {
    id: 1,
    pcImage: Card1,
    mobileImage: Card1,
    title: `파트너십 재협약 체결 및 확장`,
    dec: `BHE/BTS와의 파트너십의 또 다른 2년을 기약하는 재협약을 체결했습니다. 짝짝짝! 유니세프한국위원회에서 공식적으로 최초로 글로벌 파트너십으로 확장된 파트너십으로 앞으로의 행보가 기대됩니다. 온라인 협약을 위해 특별히 제작된 협약식 영상도 확인해보세요!`,
    pop: `#Love Myself 캠페인 활동`,
    youtube: `https://youtu.be/xnqgPfrx_wQ`,
  },
  {
    id: 2,
    pcImage: Card2,
    mobileImage: Card2,
    title: `Inspire Awards 2020 수상`,
    dec: `2019년 전 유니세프에서 가장 우수한 파트너십을 선정하는 Inspire Awards에서 LOVE MYSELF 캠페인이 가장 우수한 통합 캠페인 및 이벤트 부분에서 최우수 캠페인으로 선정되었습니다. 아동이 안전한 세상을 위해 참여해주신 모든 후원자님과 팬 분들께 감사드립니다!`,
    pop: `#Love Myself 캠페인 활동`,
    youtube: ``,
  },
  {
    id: 3,
    pcImage: Card1,
    mobileImage: Card1,
    title: `Inspire Awards 2020 수상`,
    dec: `2019년 전 유니세프에서 가장 우수한 파트너십을 선정하는 Inspire Awards에서 LOVE MYSELF 캠페인이 가장 우수한 통합 캠페인 및 이벤트 부분에서 최우수 캠페인으로 선정되었습니다. 아동이 안전한 세상을 위해 참여해주신 모든 후원자님과 팬 분들께 감사드립니다!`,
    pop: `#Love Myself 캠페인 활동`,
    youtube: ``,
  },
];
*/
const SectionSort = styled(Section)`
  padding-bottom: 0 !important;
  .process-ol {
    justify-content: center;
    .list-item {
      width: 33.33%;
      margin-bottom: 124px;

      &:nth-child(1),
      &:nth-child(2) {
        width: 50%;
      }

      .dec-ul {
        max-width: 210px;
        margin: 0 auto;
        margin-top: 25px;

        strong {
          color: #1cabe2;
          font-weight: 400;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      .list-item {
        margin-bottom: 70px;
        dt {
          padding: 0;
          .img-wrap {
            max-width: 80px;
          }
        }
        .dec-ul {
          max-width: 120px;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      .list-item {
        width: 50%;

        &:nth-child(1) {
          width: 100%;
        }
      }
    }
  }
`;

const CardFlex = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  align-items: stretch;

  & > li {
    width: 33.33%;
    padding: 0 16px;

    .card {
      height: 100%;
      position: relative;
      background: #fff;
      box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
      border-radius: 0 0 20px 0;
      padding: 40px 32px;
      padding-top: 72px;

      dt {
        display: inline-block;
        background: #1cabe2;
        border-radius: 0 0 16px 0;
        line-height: 56px;
        color: #fff;
        padding: 0 32px;
        position: absolute;
        left: 0;
        top: -16px;
      }
    }

    ${breakpoint(`tablet`)} {
      width: 100%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      .card {
        dt {
          line-height: 44px;
          padding: 0 20px;
        }
      }
    }
  }
`;
const SectionInfluence = styled(Section)``;

const SectionWedo = styled(Section)`
  ${SectionHeader} {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 316px;
      height: 316px;
      border-radius: 100%;
      background: #1cabe2;
      position: absolute;
      left: -116px;
      top: -134px;
      z-index: -1;
    }

    ${breakpoint(1160)} {
      &::before {
        width: 27.24vw;
        height: 27.24vw;
        left: -10vw;
        top: -11.55vw;
      }
    }
    ${breakpoint(`tablet`)} {
      &::before {
        width: 200px;
        height: 200px;
        left: -77px;
        top: -89px;
      }
    }
  }
  .flex-wrap {
    padding-right: 48.6%;
    position: relative;
    flex-wrap: wrap;

    .blit-list {
      width: 100%;
      font-weight: 700;
      & > li {
        margin-bottom: 16px;
        line-height: 1.7;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .col-img {
      width: 48.6%;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    ${breakpoint(`tablet`)} {
      padding-right: 0;

      .col-img {
        width: 100%;
        position: relative;
        margin-top: 8px;
      }

      .blit-list {
        & > li {
          margin-bottom: 8px;
        }
      }
    }
  }
`;

const SectionCampaign = styled(Section)`
  padding-bottom: 234px !important;

  ${breakpoint(`tablet`)} {
    padding-bottom: 120px !important;
  }
`;

const SectionBtt = styled(Section)`
  background: #1cabe2;
  padding-top: 46px;
  header {
    ${Tit} {
      line-height: 1.5;
    }
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 72px;
    position: relative;

    .col-dec {
      width: 59%;
      padding-right: 20px;

      .header-dec {
        color: #fff;
        margin-top: 32px;
        line-height: 1.7;
      }
      button {
        margin-top: 64px;
        max-width: 256px;
        width: 100%;
      }
    }

    .col-img {
      position: absolute;
      bottom: 0;
      right: -261px;
      width: 683px;
    }
  }
  ${breakpoint(1160)} {
    .flex {
      .col-img {
        right: -22.5vw;
        width: 58.88vw;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .flex {
      padding-bottom: 0;
      .col-dec {
        width: 100%;
        padding-right: 0;
        position: relative;
        z-index: 1;
        .header-dec {
          line-height: 2;
        }
        button {
          max-width: 152px;
        }
      }
      .col-img {
        position: relative;
        text-align: right;
        right: -20px;
        margin-top: -46px;
        width: 100%;
      }
    }
  }
`;

const Endviolence: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['EV01'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          title: story.subject,
          dec: story.contents,
          image: story.imgAttGrpNoTn,
          pop: `#Love Myself 캠페인 활동`,
          youtube: story.optData1,
          thumYoutube: story.optData2,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
  }, [loadSlideData]);

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="#ENDviolence"
      description="for every child, safety"
    >
      <SectionTop
        css={`
          padding-top: 32px;
        `}
      >
        <Container>
          <Row
            justifyContent="flex-end"
            css={`
              margin-bottom: 24px;
            `}
          >
            <Button
              css={`
                padding: 0 30px;
                height: 40px;
              `}
              size="sm"
              onClick={() =>
                navigate(`/what-we-do/for-every-child/endviolence/en`)
              }
            >
              EN
            </Button>
          </Row>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  BTS는 #ENDviolence를 <br />
                  지지합니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                2017년 11월 방탄소년단(BTS)과 빅히트 뮤직은 유니세프한국위원회와
                공식 파트너십을 체결하고, 전 세계 어린이·청소년이 겪는 폭력을
                알리고 이를 예방하고자 노력해왔습니다. Love Myself 캠페인은
                어린이·청소년에게 폭력 없는 안전한 세상을 만들기 위해 유니세프가
                2013년 시작한 글로벌 프로젝트인 #ENDviolence를 후원하는
                캠페인입니다. Love Myself 캠페인으로 모금된 후원금은 폭력 피해
                어린이·청소년을 지원하고, 지역사회가 폭력 예방에 힘쓰도록 제도
                정비를 촉구하는데 사용됩니다. 방탄소년단은 2018년 4월부터 진행된
                BTS 월드투어(일본, 미국, 캐나다, 영국, 홍콩, 태국 등)시, 각
                국가의 유니세프 국가위원회들과 함께 글로벌 협약을 맺고
                #ENDviolence에 대한 옹호활동을 펼쳤습니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="qV1wiMZkuCk" />
        </PageComponent>
      </SectionTop>

      <SectionSort className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">폭력에 노출된 어린이·청소년</Tit>
            </h2>
          </SectionHeader>

          <ProcessList itemData={itemData} className="ProcessList" />
        </Container>
      </SectionSort>

      <SectionInfluence className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">폭력이 어린이·청소년의 미래에 미치는 영향</Tit>
            </h2>
          </SectionHeader>
          <CardFlex>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s3">건강 문제</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    신체적 폭력을 경험한 어린이의 12%는 신체적 장애, 17%는 분노
                    장애, 21%는 알코올 중독 등에 노출됐습니다.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s3">교육 문제</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    모든 종류의 폭력에 노출된 어린이의 13%가 학교를 졸업하지
                    못한다고 예측됩니다.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s3">인격형성 문제</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    18세 이전 폭력에 노출된 성인의 경우, 그렇지 않은 경우보다
                    30배 높은 자살 충동을 경험합니다.
                  </p>
                </dd>
              </dl>
            </li>
          </CardFlex>
        </Container>
      </SectionInfluence>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                #ENDviolence를 위해 <br />
                유니세프가 하는 일
              </Tit>
            </h2>
          </SectionHeader>
          <div className="flex-wrap flex">
            <ul className="blit-list">
              <li>효율적 모금과 지원</li>
              <li>효율적인 사업방법 고안 및 공유</li>
              <li>폭력 피해 어린이·청소년을 위한 심리치료 프로그램</li>
              <li>폭력으로부터 학생들을 보호하는 제도 및 법안 정비</li>
              <li>안전한 사회망 마련</li>
              <li>양육자, 보호자, 선생님 대상 프로그램 지원</li>
            </ul>
            <div className="col-img">
              <Image pcSrc={WedoImg} mobileSrc={WedoImgM} />
            </div>
          </div>
        </Container>
      </SectionWedo>

      <SectionCampaign className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">#Love Myself 캠페인 활동</Tit>
            </h2>
          </SectionHeader>
          {slideData.length > 0 && (
            <CampaignCard2 campaigns={slideData} overflowHidden />
          )}
        </Container>
      </SectionCampaign>

      <SectionBtt>
        <Container>
          <header>
            <h2>
              <Tit size="s1" color="white" weight="normal">
                소중한 후원으로 폭력에 고통받는 어린이와 <br />
                청소년들을 도와주세요
              </Tit>
            </h2>
          </header>
          <div className="flex">
            <div className="col-dec">
              <p className="header-dec">
                유니세프는 전세계에서 일어나는 아동 및 청소년 대상의 폭력을
                막고자 각 국가의 정부 및 NGO, 협력 기관들과 함께 노력하고
                있습니다. 지금 이 순간에도 폭력의 고통 속에서 두려움에 떨고 있을
                어린이와 청소년들을 위해 함께해주세요. 여러분의 따뜻한 관심과
                후원으로 어린이와 청소년들을 지켜줄 수 있는 든든한 울타리와
                따뜻한 지역사회를 만들 수 있습니다.
              </p>

              <Button
                color="yellow"
                title="Endviolence-후원하기"
                onClick={() =>
                  navigate(`${process.env.BACKEND_URL}/campaign/endviolence/dn`)
                }
              >
                후원하기
              </Button>
            </div>
            <div className="col-img">
              <Image pcSrc={BttImg} mobileSrc={BttImgM} />
            </div>
          </div>
        </Container>
      </SectionBtt>
    </LayoutWithTitle>
  );
};

export default Endviolence;
